<template>
    <aside class=" aside-section">
        <button class="navbar-toggler" id="asideCollapseControl" type="button" data-toggle="collapse" data-target="#asideCollapse"
                aria-controls="asideCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-bars"></i>
        </button>

        <div class="tabs aside-tabs collapse navbar-collapse" id="asideCollapse">

            <ul class="nav nav-tabs justify-content-center flex-column">
                <li>
                    <a data-toggle="tab" href="#profile" class="tab-link active show">
                        <span class="tab-header">

                            <svg xmlns="http://www.w3.org/2000/svg" width="20.254" height="20.254"
                                 viewBox="0 0 20.254 20.254">
                              <path id="Path_203996" data-name="Path 203996"
                                    d="M145.283,145.294c-.022.022-.048.037-.07.057a10.265,10.265,0,0,1-1.427,1.171c-.059.04-.123.072-.184.112a10.123,10.123,0,0,1-1.547.827c-.064.028-.131.046-.2.072a10.145,10.145,0,0,1-1.714.519c-.037.007-.074.009-.109.017a9.9,9.9,0,0,1-3.819,0c-.037-.007-.074-.009-.109-.017a9.981,9.981,0,0,1-1.714-.519c-.064-.026-.131-.044-.2-.072a9.965,9.965,0,0,1-1.547-.827c-.061-.039-.125-.072-.184-.112a10.249,10.249,0,0,1-1.427-1.171c-.022-.022-.048-.037-.07-.057,0,0,0-.011-.007-.015a10.127,10.127,0,1,1,14.325,0S145.286,145.29,145.283,145.294Zm-12.131-.584c.193.145.385.293.589.418.166.1.342.188.516.28a8.592,8.592,0,0,0,7.741,0c.173-.092.35-.177.516-.28.2-.125.4-.272.589-.418.145-.111.283-.228.422-.348a5.931,5.931,0,0,0-10.8,0C132.867,144.482,133.007,144.6,133.152,144.71Zm7.737-8.424a2.762,2.762,0,1,0-2.762,2.762A2.765,2.765,0,0,0,140.889,136.286Zm-2.762-6.445a8.257,8.257,0,0,0-6.717,13.1,7.861,7.861,0,0,1,3.662-3.237,4.6,4.6,0,1,1,6.109,0,7.861,7.861,0,0,1,3.662,3.237,8.206,8.206,0,0,0,1.569-4.811A8.3,8.3,0,0,0,138.127,129.841Z"
                                    transform="translate(-128 -128)" fill="#000"/>
                            </svg>
                             {{ $t('settings.general') }}
                        </span>
                        <span class="tab-arrow" aria-hidden="true">
                            <i class="fas fa-angle-left" aria-hidden="true"></i>
                        </span>
                    </a>
                </li>
                <li>
                    <a data-toggle="tab" href="#orders" class="tab-link">
                    <span class="tab-header">

                        <svg xmlns="http://www.w3.org/2000/svg" width="17.369" height="21.771"
                             viewBox="0 0 17.369 21.771">
                          <g id="shopping-bag" transform="translate(0.252 0.25)">
                            <g id="Group_41316" data-name="Group 41316" transform="translate(0)">
                              <g id="Group_41315" data-name="Group 41315">
                                <path id="Path_203997" data-name="Path 203997"
                                      d="M69.875,17.541l-.95-11.969A1.5,1.5,0,0,0,67.441,4.2H65.564V4.112a4.112,4.112,0,0,0-8.223,0V4.2H55.465A1.5,1.5,0,0,0,53.98,5.57l-.95,11.973a3.463,3.463,0,0,0,3.453,3.728h9.939a3.463,3.463,0,0,0,3.453-3.73ZM58.587,4.112a2.866,2.866,0,0,1,5.731,0V4.2H58.587Zm9.462,15.2a2.195,2.195,0,0,1-1.627.711H56.483a2.217,2.217,0,0,1-2.211-2.385l.95-11.973a.244.244,0,0,1,.243-.225h1.876V6.976a.623.623,0,0,0,1.246,0V5.442h5.731V6.976a.623.623,0,0,0,1.246,0V5.442h1.876a.245.245,0,0,1,.243.226l.95,11.969A2.195,2.195,0,0,1,68.049,19.314Z"
                                      transform="translate(-53.02)" fill="#130202" stroke="#04562d" stroke-width="0.5"/>
                              </g>
                            </g>
                            <g id="Group_41318" data-name="Group 41318" transform="translate(5.444 10.382)">
                              <g id="Group_41317" data-name="Group 41317" transform="translate(0)">
                                <path id="Path_203998" data-name="Path 203998"
                                      d="M189.848,250.075a.623.623,0,0,0-.881,0l-2.782,2.782-1.07-1.07a.623.623,0,0,0-.881.881l1.51,1.51a.623.623,0,0,0,.881,0l3.222-3.222A.623.623,0,0,0,189.848,250.075Z"
                                      transform="translate(-184.052 -249.893)" stroke="#000" stroke-width="0.5"/>
                              </g>
                            </g>
                          </g>
                        </svg>

                             {{ $t('settings.my-orders') }}
                        </span>
                        <span class="tab-arrow" aria-hidden="true">
                            <i class="fas fa-angle-left" aria-hidden="true"></i>
                        </span>
                    </a>
                </li>
                <li>
                    <a data-toggle="tab" href="#addresses" class="tab-link">
                    <span class="tab-header">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.255" height="19.82"
                                 viewBox="0 0 20.255 19.82">
                              <path id="address"
                                    d="M.233,10.056a.791.791,0,0,0,1.119,0l.626-.63V12.3a.791.791,0,0,0,1.582,0V7.83L9.577,1.773a.791.791,0,0,1,1.027,0L16.7,7.85c0,.008,0,.015,0,.023V16.26a1.584,1.584,0,0,1-1.582,1.582h-.989a.791.791,0,1,0,0,1.582h.989a3.168,3.168,0,0,0,3.165-3.165V9.428l.628.627a.791.791,0,0,0,1.118-1.12L11.7.632,11.67.6A2.372,2.372,0,0,0,8.506.608L8.474.639.229,8.937A.791.791,0,0,0,.233,10.056Zm2.536,5.412a.791.791,0,0,1,.791.791,1.584,1.584,0,0,0,1.582,1.582h.989a.791.791,0,1,1,0,1.582H5.142A3.168,3.168,0,0,1,1.978,16.26.791.791,0,0,1,2.769,15.469Zm2.374-2.894c0,2.119,1.262,3.481,2.481,4.8a14.683,14.683,0,0,1,1.7,2.079.791.791,0,0,0,.669.368h.272a.791.791,0,0,0,.669-.368,14.683,14.683,0,0,1,1.7-2.079c1.219-1.317,2.479-2.679,2.481-4.8a4.985,4.985,0,0,0-9.969,0Zm1.582,0a3.4,3.4,0,0,1,6.8,0c0,1.5-.91,2.478-2.06,3.721-.451.488-.913.987-1.342,1.547-.429-.56-.891-1.059-1.342-1.547C7.635,15.055,6.726,14.073,6.725,12.575Zm3.438-2.17a2.176,2.176,0,1,1,0,4.352.791.791,0,1,1,0-1.582.593.593,0,1,0-.593-.593.791.791,0,1,1-1.582,0A2.178,2.178,0,0,1,10.163,10.4Zm0,0"
                                    transform="translate(0.001 0)" fill="#fff"/>
                            </svg>
                              {{ $t('settings.address') }}
                        </span>
                        <span class="tab-arrow" aria-hidden="true">
                            <i class="fas fa-angle-left" aria-hidden="true"></i>
                        </span>
                    </a>
                </li>
                <li>
                    <a data-toggle="tab" href="#changePassword" class="tab-link">
                           <span class="tab-header">


                                <svg id="_006-close-envelope" data-name="006-close-envelope"
                                     xmlns="http://www.w3.org/2000/svg" width="14.49" height="19.32"
                                     viewBox="0 0 14.49 19.32">
                                  <g id="lock" transform="translate(0 0)">
                                    <g id="Group_308" data-name="Group 308">
                                      <path id="Path_636" data-name="Path 636"
                                            d="M64.4,7.245H65.61V5.635a5.635,5.635,0,1,1,11.27,0v1.61h1.207a.4.4,0,0,1,.4.4V17.71a1.612,1.612,0,0,1-1.61,1.61H65.61A1.612,1.612,0,0,1,64,17.71V7.647A.4.4,0,0,1,64.4,7.245Zm5.637,8.408a.4.4,0,0,0,.4.447h1.61a.4.4,0,0,0,.4-.447L72.2,13.37a1.592,1.592,0,0,0,.659-1.295,1.61,1.61,0,1,0-3.22,0,1.592,1.592,0,0,0,.659,1.295ZM68.025,7.245h6.44V5.635a3.22,3.22,0,0,0-6.44,0Z"
                                            transform="translate(-64)" fill="#130202"/>
                                    </g>
                                  </g>
                                </svg>

                            {{ $t('settings.change-password') }}
                        </span>
                        <span class="tab-arrow" aria-hidden="true">
                            <i class="fas fa-angle-left" aria-hidden="true"></i>
                        </span>
                    </a>
                </li>
                <li>
                    <a data-toggle="tab" href="#points" class="tab-link">
                           <span class="tab-header">

                    <svg xmlns="http://www.w3.org/2000/svg" width="17.412" height="21.166" viewBox="0 0 17.412 21.166">
                      <g id="history" transform="translate(0.15 0.15)">
                        <path id="Path_204334" data-name="Path 204334"
                              d="M62.4,5.311V18.859a2.01,2.01,0,0,1-2.007,2.007H51.235a2.009,2.009,0,0,1-2.007-2.007V10.05a4.937,4.937,0,0,0,1.505.038v8.771a.5.5,0,0,0,.5.5h9.158a.5.5,0,0,0,.5-.5V5.311a.5.5,0,0,0-.5-.5H55.425a5.308,5.308,0,0,0-.22-1.505h5.187A2.009,2.009,0,0,1,62.4,5.311ZM50.022,9.468a4.734,4.734,0,1,1,4.734-4.734A4.739,4.739,0,0,1,50.022,9.468Zm0-1a3.73,3.73,0,1,0-3.73-3.73A3.734,3.734,0,0,0,50.022,8.464Zm2.839-3.228a.5.5,0,0,0,0-1H50.524V2.262a.5.5,0,1,0-1,0V4.734a.5.5,0,0,0,.5.5ZM58.653,9.3H53.217a.5.5,0,0,0,0,1h5.436a.5.5,0,1,0,0-1Zm.009,3.044H53.225a.5.5,0,1,0,0,1h5.436a.5.5,0,0,0,0-1Zm0,3.17H53.225a.5.5,0,1,0,0,1h5.436a.5.5,0,0,0,0-1Z"
                              transform="translate(-45.288)" fill="#130202" stroke="#130202" stroke-width="0.3"/>
                      </g>
                    </svg>


                             {{ $t('settings.my-points') }}
                        </span>
                        <span class="tab-arrow" aria-hidden="true">
                            <i class="fas fa-angle-left" aria-hidden="true"></i>
                        </span>
                    </a>
                </li>
            </ul>

            <button  @click="handleLogout" class="tab-link logout btn btn-outline-light">
               <span>
                    <img src="@/assets/img/logout.svg" alt="">
                {{ $t('nav.logout') }}
               </span>
                <span class="tab-arrow" aria-hidden="true">
                            <i class="fas fa-angle-left" aria-hidden="true"></i>
                </span>
            </button>
        </div>
    </aside>
</template>

<script>
import {logOut} from "@/services/auth";

export default {
    name: "Aside",
    methods: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        handleLogout() {
            logOut().then(() => {
                localStorage.removeItem('access_token')
                this.$router.push('/login');
                location.reload();
                this.$store.dispatch("fetchUser");
                this.$store.dispatch("resetAll");
            })
                .catch(e => console.error(e))
        }
    }
}
</script>