<template>
    <section class="card setting-card">
        <h2 class="text-center heading"> {{ $t('settings.my-points') }}</h2>
        <div class="points-wrapper">
            <div class="points-card">
                <h3>{{ $t('settings.points-heading') }}</h3>
                <p>
                    {{points}}
                     {{ $t('settings.point') }}
                </p>
            </div>
        </div>
    </section>
</template>

<script>
import {getPoints} from "@/services/points";

export default {
    name: "Points",
    data(){
        return {
            points:0
        }
    },
    mounted() {
        this.fetchPoints()
    },
    methods:{
        fetchPoints() {
            getPoints().then(res=>{
                this.points = res.data.data.User.Points[0]
            })
        }
    }
}
</script>

<style scoped>

</style>