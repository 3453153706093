<template>

    <!-- Modal -->
    <div class="modal fade" id="edit_modal" tabindex="-1" aria-labelledby="edit_modalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <button type="button" class="close" id="hideModal" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-header justify-content-center">
                    <h5 class="modal-title" id="edit_modalLabel">{{ $t('settings.edit-profile') }} </h5>
                </div>
                <div class="modal-body">
                    <form action="" class="setting-form" @submit.prevent="editProfile"  enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-md-6 col-12 mx-auto">
                                <div class="form-group">
                                    <div class="form-group text-center">
                                        <label for="user_avatar" class="user_avatar_placeholder">
                                            <i class="fas fa-camera camera_icon"></i>
                                            <img :src="userData.image ? userData.image : require('@/assets/img/no-avatar.png')" class="avatar__thump"
                                                 :alt="user.name">
                                        </label>
                                        <input class="form-control" id="user_avatar" @change="previewImage"
                                               type="file"/>
                                    </div>

                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="full_name">{{ $t('Name') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                    <span class="input-group-text" id="username">
                                        <img src="@/assets/img/profile.png" alt="user icon">
                                    </span>
                                        </div>
                                        <input class="form-control" id="full_name" type="text" v-model="userData.name"
                                               :placeholder="$t('Name')" required/>
                                    </div>

                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>{{ $t('Country') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fas fa-map-marker-alt text-white"></i>
                                    </span>
                                        </div>
                                        <select v-model="country_id" class="form-control">
                                            <option v-for="country in countries" :key="country.id" :value="country.id">
                                                {{ country.name }}
                                            </option>
                                        </select>
                                    </div>

                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>{{ $t('city') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon2">
                                        <i class="fas fa-city text-white"></i>
                                    </span>
                                        </div>
                                        <select v-model="userData.city_id" class="form-control">
                                            <option v-for="city in cities" :key="city.id" :value="city.id" :selected="city.id === user.city_id">
                                                {{ city.name }}
                                            </option>
                                        </select>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="email_address">{{ $t('Email Address') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                    <span class="input-group-text" id="email">
                                        <img src="@/assets/img/mail.png" alt="user icon">
                                    </span>
                                        </div>
                                        <input class="form-control" id="email_address" type="email"
                                               v-model="userData.email" placeholder="Example@gmail.com" required/>
                                    </div>

                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label for="user_phone">{{ $t('Phone') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                    <span class="input-group-text" id="phone_icon">
                                        <img src="@/assets/img/phone.png" alt="phone icon">
                                    </span>
                                        </div>
                                        <input class="form-control" id="user_phone" type="tel"
                                               v-model="userData.mobile" required/>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <button type="submit" class="btn w-100 btn-save" :disabled="loading">
                            <span v-if="!loading"> {{ $t('settings.save-changes') }} </span>
                            <span class="loading" v-else>{{ $t('loading') }}</span>
                        </button>

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import { updateProfile} from "@/services/auth";
import store from "@/store";

export default {
    name: 'EditModal',
    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            user: "user",
            countries:"countries"
        }),

    },
    watch:{
        country_id : function() {
            let userCountry = this.countries.find(country=>country.id === this.country_id);
            this.cities = userCountry.Cities;
        }
    },
    data() {
        return {
            userData: [],
            country_id:null,
            toast: useToast(),
            loading: false,
            cities:[]
        }
    },
    mounted() {
        this.fetchUser()
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        fetchUser() {
            this.userData = {...this.user.data}
            this.country_id = this.user.data.country_id;
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        editProfile() {
            this.loading = true
            let success_msg = this.$i18n.locale === 'ar' ? 'تم تحديث البيانات بنجاح' : 'Profile updated successfully'
            let data = {...this.userData,country_id : this.country_id}
            console.log(data)
            updateProfile(data)
                .then(res => {
                    if (res.data.status.status === 'fail') {
                        this.toast.error(res.data.status.message[0]);
                    } else {
                        store.dispatch("fetchUser", res.data.User);
                        setTimeout(() => {
                            this.toast.success(success_msg);
                            this.hideModal()
                        }, 400)
                    }

                    this.loading = false

                }).catch(e => {
                console.log(e)
                this.loading = false
            })
        },
        previewImage() {
            let avatarFile = document.querySelector('#user_avatar');
            const thump = document.querySelector('.avatar__thump');
            const noUserThump = './images/no-user.png';
            let newSrc = '' || noUserThump;

            const thumpFile = avatarFile.files;
            if (thumpFile.length === 0) {
                thump.src = noUserThump
            } else {
                newSrc = URL.createObjectURL(thumpFile[0]);
                thump.src = newSrc;
                console.log(thumpFile[0])
                this.userData.image = thumpFile[0];
                thump.style.opacity = "1";
            }
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        hideModal() {
            document.getElementById('hideModal').click();
        }
    }
}
</script>

<style>
#edit_modal .modal-content {
    border: 0;
}

#edit_modal .setting-form {
    padding: 0 2rem;
}

#user_avatar {
    display: none;
}

.user_avatar_placeholder {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    display: inline-block;
    cursor: pointer;
    position: relative;
    margin-bottom: 1rem;
}

.camera_icon {
    position: absolute;
    bottom: .5rem;
    right: 0;
    font-size: 1.25em;
    color: #70726f;
    border: 1px solid #fff;
    border-radius: 50%;
    padding: .35rem;
    z-index: 1;
    background-color: rgba(255,255,255,.9);
    box-shadow: 0 5px 5px rgba(0,0,0,.06);
}

.avatar__thump {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    opacity: .5;
}

.user_avatar_placeholder:hover > .avatar__thump, .user_avatar_placeholder:hover > .camera_icon {
    opacity: 1;
    color: #4b4b4b;
}

@media (min-width: 992px) {

    #edit_modal .modal-dialog {
        max-width: 748px;
    }

    #edit_modal .setting-form {
        padding: 0 5rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

}
</style>