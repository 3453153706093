<template>
    <section class="card setting-card">
        <h2 class="text-center heading">{{ $t('settings.my-orders') }}</h2>
        <Loader v-if="loading"/>
        <div v-else>
            <nav class="container mt-2">
            <ul class="nav nav-pills p-0">
                <li class="nav-item w-50">
                    <button class="btn shadow-none rounded-0 btn-outline-primary w-100"
                            :class="`${activeTab === 'orders' ? 'active': ''}`"
                            @click="toggleTab('orders')">
                        {{$t('settings.my-orders')}}
                    </button>
                </li>
                <li class="nav-item w-50">
                    <button class="btn shadow-none rounded-0 btn-outline-primary w-100"
                            :class="`${activeTab === 'old-orders' ? 'active': ''}`"

                            @click="toggleTab('old-orders')">
                        {{$t('settings.previous-orders') }}
                    </button>
                </li>
            </ul>
        </nav>
            <div class="table-responsive table-custom" v-if="orders.length">
                <div class="orders-table">

                    <table class="table  table-hover border">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{{ $t('order.number') }}</th>
                            <th>{{ $t('order.date') }}</th>
                            <th>{{ $t('order.status') }}</th>
                            <th>{{ $t('order.total_price') }}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(order,index) in orders" :key="order">
                            <td>{{ index + 1 }}</td>
                            <td>{{ order.id }}</td>
                            <td>{{ order.order_status[0].order_status_date }}</td>
                            <td>{{ order.status }}</td>
                            <td>{{ order.total }} {{ currency.coin.name }}</td>
                            <td>
                                <router-link :to="{ name: 'OrderDetails', params: { id: order.id }}"
                                             class="btn btn-details">
                                    {{ $t('order.details') }}
                                </router-link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <pagination v-if="total > 10" v-model="page" :records="total" :per-page="10"
                                @paginate="getUserOrders"/>
                </div>

            </div>
            <Empty v-else/>
        </div>
    </section>
</template>

<script>
import {getOrders} from "@/services/orders";
import Empty from "@/views/Cart/components/EmptyCart";
import store from "@/store";
import Loader from "@/components/Loader";

import Pagination from 'v-pagination-3';

const OrderStatus = {
    1: {name: "New", ar_name: "جديد"},
    2: {name: "Canceled", ar_name: "ملغي"},
    3: {name: "InProgress", ar_name: "قيد المراجعة"},
    4: {name: "Rejected", ar_name: "مرفوض"},
    5: {name: "Accepted", ar_name: "مقبول"},
    6: {name: "InDelivery", ar_name: "جاري التوصيل"},
    7: {name: "Received", ar_name: "تم التوصيل"},
    8:{name: "Reviewed", ar_name: "تم تقييمه"}
}

export default {
    name: "Orders",
    components: {Loader, Empty, Pagination},
    data() {
        return {
            orders: [],
            loading: true,
            response: null,
            activeTab: 'orders',
            page: 1,
            total: 0,
        }
    },
    computed: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        currency: function () {
            return store.getters.getCurrency;
        },
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    mounted() {
        this.getUserOrders();
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        getUserOrders(page = 1) {
            this.loading = true;
            getOrders(page,0).then(res => {
                this.loading = false;
                this.orders = res.data.Orders.map(order => {
                    order.status = this.$i18n.locale === 'ar' ? OrderStatus[order.status]['ar_name'] : OrderStatus[order.status]['name']
                    return order;
                });
                this.response = res;
                this.total = res.data.paging.total
            })
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        getOldOrders(page=1) {
            this.loading = true;
            getOrders(page,1).then(res => {
                this.loading = false;
                this.orders = res.data.Orders.map(order => {
                    order.status = this.$i18n.locale === 'ar' ? OrderStatus[order.status]['ar_name'] : OrderStatus[order.status]['name']
                    return order;
                });
                this.response = res;
                this.total = res.data.paging.total
            })
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        toggleTab(tab) {
            this.activeTab = tab
            tab === 'orders' ? this.getUserOrders() : this.getOldOrders()
        }
    }
}
</script>

<style scoped>

</style>