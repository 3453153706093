<template>
    <div class="tab-content">
        <div id="profile" class="tab-pane fade active show">
            <General/>
        </div>
        <div id="orders" class="tab-pane fade">
             <Orders/>
        </div>
        <div id="addresses" class="tab-pane fade">
            <Address/>
        </div>
        <div id="changePassword" class="tab-pane fade">
            <ChangePassword/>
        </div>
        <div id="points" class="tab-pane fade">
            <Points/>
        </div>
    </div>
</template>

<script>
import General from "@/views/Settings/General";
import Orders from "@/views/Settings/Orders";
import Address from "@/views/Settings/Address";
import Points from "@/views/Settings/Points";
import ChangePassword from "@/views/Settings/ChangePassword";

export default {
    name:'TabContent',
    components: {ChangePassword, Points, Address, Orders, General}
}
</script>