<template>
    <section class="card setting-card">
        <h2 class="text-center heading">{{ $t('settings.change-password') }}</h2>
        <div class="setting-body">
            <form action="" class="setting-form change-password" @submit.prevent="handleChangePassword">
                <div v-if="hasError" class="alert alert-danger font-weight-bold error_msg">
                    {{$t('old_password_error')}}
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="old_password">{{ $t('old_password') }}</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="old">
                                        <img src="@/assets/img/key.png" alt="key icon">
                                    </span>
                                </div>
                                <input class="form-control" v-model="v$.form.old_password.$model" id="old_password"
                                       type="password" :placeholder="$t('old_password')" required/>
                            </div>

                            <div class="text-danger mt-1 font-weight-bold error_msg" v-if="v$.form.old_password.$error && form.old_password.length > 0">
                                {{ v$.form.old_password.$errors[0].$message }}
                            </div>

                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group" :class="{ 'form-group--error': v$.form.password.$error && form.password.length > 0}">
                            <label for="new_password">{{ $t('new_password') }}</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="password">
                                        <img src="@/assets/img/password.png" alt="password">

                                    </span>
                                </div>
                                <input class="form-control" v-model="v$.form.password.$model" id="new_password"
                                       type="password"
                                       :placeholder="$t('new_password')" required/>
                            </div>

                            <div class="text-danger mt-1 font-weight-bold error_msg" v-if="v$.form.password.$error && form.password.length > 0">
                                {{ v$.form.password.$errors[0].$message }}
                            </div>

                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group" :class="{ 'form-group--error': v$.form.password_confirmation.$error && form.password_confirmation.length > 0 }">
                            <label for="password_confirm">{{ $t('confirm_password') }}</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="password_c">
                                        <img src="@/assets/img/password.png" alt="password">
                                    </span>
                                </div>
                                <input class="form-control" v-model="v$.form.password_confirmation.$model"
                                       id="password_confirm" type="password"
                                           :placeholder="$t('confirm_password')" required/>
                            </div>

                            <div class="text-danger mt-1 font-weight-bold error_msg" v-if="v$.form.password_confirmation.$error && form.password_confirmation.length > 0">
                                {{ $t('password_error') }}
                            </div>

                        </div>
                    </div>

                </div>

                <button type="submit" class="btn w-100 btn-save" :disabled="loading">

                    <span v-if="!loading">  {{ $t('settings.save-changes') }} </span>
                    <span class="loading" v-else>{{ $t('loading') }}</span>
                </button>

            </form>
        </div>
    </section>
</template>

<script>
import useValidate from '@vuelidate/core';
import {required, sameAs, minLength, helpers} from '@vuelidate/validators';
import {changePassword} from "@/services/auth";
import {useToast} from "vue-toastification";

export default {
    name: "ChangePassword",
    data() {
        return {
            v$: useValidate(),
            toast: useToast(),
            form: {
                old_password: '',
                password: '',
                password_confirmation: '',
            },
            loading: false,
            hasError:false,
            success:false
        }
    },
    validations() {
        let required_msg = this.$i18n.locale === 'ar' ? 'هذا الحقل مطلوب' : 'This field cannot be empty'

        return {
          form: {
              old_password: {
                  required :helpers.withMessage(required_msg, required),
              },
              password: {
                  required :helpers.withMessage(required_msg, required),
                  min: minLength(6),
              },
              password_confirmation: {
                  required :helpers.withMessage(required_msg, required),
                  sameAsPassword: sameAs(this.form.password)
              },
          }
        }
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        handleChangePassword() {
            this.loading = true;
            this.v$.$validate() // checks all inputs
            if (this.v$.$error) { // if ANY fail validation
                this.loading = false;
                return false
            }

            try {
                changePassword(this.form).then(res=>{
                    if(res.data.status.status === 'fail') {
                        this.hasError = true
                    }else  {
                        this.form.password = '';
                        this.form.old_password = '';
                        this.form.password_confirmation = '';
                        this.toast.success(this.$t('password_changed'));

                        this.hasError = false;

                    }
                    this.loading = false
                })

            }
            catch (e){
                this.loading=false
                console.log(e)
            }
        }
    }
}
</script>

<style scoped>

</style>