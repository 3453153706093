<template>
    <section class="card setting-card">
        <h2 class="text-center heading">{{ $t('settings.general-heading') }}</h2>
        <div class="setting-body">
            <button class="btn rounded-circle btn-edit" type="button" data-toggle="modal" data-target="#edit_modal">
                <span class="sr-only">Edit profile</span>
                <svg id="edit" xmlns="http://www.w3.org/2000/svg" fill="#0e5aa6" width="15.373" height="15.372" viewBox="0 0 15.373 15.372">
                    <g id="Group_54464" data-name="Group 54464" transform="translate(0 10.607)">
                        <g id="Group_54463" data-name="Group 54463">
                            <path id="Path_201400" data-name="Path 201400" d="M1.537,353.28,0,358.045l4.766-1.537Z" transform="translate(0 -353.28)"/>
                        </g>
                    </g>
                    <g id="Group_54466" data-name="Group 54466" transform="translate(2.617 2.002)">
                        <g id="Group_54465" data-name="Group 54465" transform="translate(0 0)">
                            <rect id="Rectangle_225" data-name="Rectangle 225" width="10.607" height="4.612" transform="translate(0 7.5) rotate(-45)"/>
                        </g>
                    </g>
                    <g id="Group_54468" data-name="Group 54468" transform="translate(11.222)">
                        <g id="Group_54467" data-name="Group 54467">
                            <path id="Path_201401" data-name="Path 201401" d="M377.68,2.383,375.528.231a.743.743,0,0,0-1.076,0l-.692.692,3.228,3.228.692-.692A.743.743,0,0,0,377.68,2.383Z" transform="translate(-373.76 0)"/>
                        </g>
                    </g>
                </svg>
            </button>
            <form action="" class="setting-form">
                <div class="row">
                    <div class="col-md-6 col-12">
                        <div class="form-group">
                            <label for="name">{{ $t('Name') }}</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="username">
                                        <img src="@/assets/img/profile.png" alt="user icon">
                                    </span>
                                </div>
                                <input class="form-control bg-white" id="name" type="text" disabled :value="user.data.name"/>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-6 col-12">
                        <div class="form-group">
                            <label for="country">{{ $t('Country') }}</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fas fa-map-marker-alt text-white"></i>
                                    </span>
                                </div>
                                <input class="form-control bg-white" id="country" type="text" disabled :value="user.data.Country.name"/>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 col-12">
                        <div class="form-group">
                            <label for="user_email">{{ $t('Email Address') }}</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="email">
                                        <img src="@/assets/img/mail.png" alt="user icon">
                                    </span>
                                </div>
                                <input class="form-control bg-white" id="user_email" type="email" disabled :value="user.data.email"/>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-6 col-12">
                        <div class="form-group">
                            <label for="phone">{{$t('Phone')}}</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="phone_icon">
                                        <img src="@/assets/img/phone.png" alt="phone icon">
                                    </span>
                                </div>
                                <input class="form-control bg-white" id="phone" type="tel" disabled :value="user.data.mobile"/>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-6 col-12">
                        <div class="form-group">
                            <label for="phone">Affiliate Code</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="Affiliate">
                                        <i class="far fa-share-square text-white"></i>
                                    </span>
                                </div>
                                <input class="form-control bg-white" id="code" type="tel" disabled :value="user.data.affiltiate_code"/>
                            </div>
                            <small class="hint mt-2 d-inline-block">Send code to your friend to gain points</small>

                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>

</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "General",
    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            user: "user"
        })
    },

}
</script>

