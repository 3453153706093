<template>
    <section class="card setting-card">
        <h2 class="text-center heading">{{ $t('settings.address') }}</h2>
        <transition name="component-fade" mode="out-in">
            <div class="address-container" v-if="this.hideAddLocation">
                <div v-if="!loading">
                    <AddressBox v-for="user_address in address" :key="user_address.id" :address="user_address"
                                @getUserAddress="getUserAddress" @handleDelete="address_id = user_address.id" :preventDelete="false"
                                :isChecked="selectedAddress" @getSelectedAddress="getSelectedAddress"/>

                    <button type="button" class="btn w-100 btn-save mt-0" @click="toggleSection">

                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="14.584" height="18.09"
                             viewBox="0 0 14.584 18.09">
                            <path id="pin-add"
                                  d="M10.825,18.625l-.533.465-.533-.465C5.278,14.712,3,11.3,3,8.292A7.184,7.184,0,0,1,10.292,1a7.184,7.184,0,0,1,7.292,7.292C17.584,11.3,15.306,14.712,10.825,18.625ZM4.62,8.292c0,2.309,1.869,5.213,5.672,8.64,3.8-3.427,5.672-6.331,5.672-8.64A5.567,5.567,0,0,0,10.292,2.62,5.567,5.567,0,0,0,4.62,8.292Zm4.861-.81V5.051H11.1V7.482h2.431V9.1H11.1v2.431H9.482V9.1H7.051V7.482Z"
                                  transform="translate(-3 -1)" fill="#fff" fill-rule="evenodd"/>
                        </svg>

                        {{ $t('settings.add-location') }}
                    </button>
                </div>
                <Loading v-else/>
            </div>


            <Add_address v-else @handleToggle="toggleSection"/>

        </transition>
        <ConfirmDialog :status="deleting" @handleDelete="deleteAddress(address_id)"/>
    </section>
</template>

<script>
import AddressBox from "@/views/Settings/components/AddressBox";
import Add_address from "@/views/Settings/components/AddAddress";
import {getAddresses, deleteAddress, showAddress} from "@/services/address";
import Loading from "@/components/Loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import {useToast} from "vue-toastification";
import {updateProfile} from "@/services/auth";

export default {
    name: "Address",
    components: {Loading, Add_address, AddressBox, ConfirmDialog},
    data() {
        return {
            toast: useToast(),
            hideAddLocation: true,
            loading: false,
            address: [],
            selectedAddress: null,
            deleting: false,
            address_id: null
        }
    },
    mounted() {
        this.getUserAddress()
    },
    methods: {
        toggleSection() {
            this.hideAddLocation = !this.hideAddLocation;
            this.getUserAddress();
        },
        getUserAddress() {
            this.loading = true;
            getAddresses().then(res => {
                this.address = res.data.Addresses
                this.loading = false;
            })
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        getSelectedAddress(id) {
            let prevVal = this.selectedAddress;
            this.selectedAddress = prevVal === id ? null : id
            showAddress({address_id: id})
                .then(res => {
                    updateProfile({
                        ...this.$store.getters.user.data,
                        address: res.data.Address.id
                    }).then(res => console.log(res))
                    prevVal === id ?
                        this.$store.dispatch('fetchAddress', null) :
                        this.$store.dispatch('fetchAddress', res.data.Address)
                })
        },

        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        deleteAddress(id) {
            this.deleting = true
            deleteAddress({address_id: id}).then(res => {
                document.getElementById('hideModalConfirm').click();
                if (res.data.status.status === 'fail') {
                    this.toast.error(this.$t('error_msg'), {timeout: 4000, closeButton: false,})
                } else {
                    this.toast.success(this.$t('success_msg'));
                    this.getUserAddress()
                }
                this.deleting = false

            })

        }
    }

}
</script>

<style>
@media (min-width: 992px) {
    .setting-card {
        min-height: 500px;
    }
}
</style>