<template>
    <BaseLayout>
        <template #page-content>
            <div class="container-fluid">
                <div class="settings-wrapper mr-custom ml-custom">
                    <keep-alive>
                        <Aside/>
                    </keep-alive>
                    <TabContent/>

                    <EditModal/>
                </div>
            </div>
        </template>
    </BaseLayout>
</template>

<script>
import BaseLayout from "@/containers/BaseLayout";
import Aside from "@/views/Settings/components/Aside";
import TabContent from "@/views/Settings/components/TabContent";
import EditModal from "@/views/Settings/components/EditModal";


export default {
    name: "Settings",
    components: {EditModal, TabContent, Aside, BaseLayout}
}
</script>